.l-nav {
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
  @include mobile {
    margin-bottom: 0;
    border: 0;
  };
  &__inner {
    max-width: $size-col-width;
    margin: auto;
    position: relative;
    height: $size-nav-height;
    @include mobile {
      height: $size-mobile-nav-height;
    };
    .links {
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      list-style: none;
      margin: auto;
      padding: 0;
      li {
        @include text-variable-size($font-size-base);
        display: inline;
        margin-left: 24px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
      }
      a {
        text-decoration: none;
        color: $color-black;
        opacity: 0.75;
        &.hidden {
          @include mobile {
            display: none;
          };
        }
        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }
    a.store {
      opacity: 1;
      margin: 12px auto;
      line-height: $size-nav-height;
      color: #fff;
      display: inline-block;
      padding: 0 24px;
      line-height: 40px;
      background-color: $color-black;
      text-decoration: none;
      transition: background-color 0.25s;

      @include mobile {
        line-height: $size-mobile-nav-height;
        margin: 0;

      };
      &:hover {
        text-decoration: none;
        background-color: $color-gold;
      }
    }
    .logo {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 32px;
      @include mobile {
        height: 24px;
        left: 8px;
      };
      img {
        height: 100%;
        vertical-align: middle;
        display: inline-block;

      }
    }
  }
}
