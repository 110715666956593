.c-cta {
	@extend %layout-column;
	background-color: $color-black;
	margin-top: 48px;
	margin-bottom: 48px;
	padding: 48px 0;
	@include mobile {
		padding: 24px 16px;
	};
	&__title {
		@include text-variable-size($font-size-large);
		@extend %linebreaks;
		text-align: center;
		line-height: $line-height-default;
		color: #fff;
	}
	&__button {
		@include text-variable-size($font-size-base);
		max-width: 300px;
		margin: auto;
		text-align: center;
		color: $color-text-default;
		line-height: 56px;
		background-color: #fff;
		display: block;
		text-decoration: none;
		transition: background-color 0.25s;

		&:hover {
			background-color: $color-gold;
			color: #fff;
		}
	}
}
