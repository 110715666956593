.l-footer {
  border-top: 1px solid $color-border;
	max-width: $size-col-width;
	margin: auto;
	padding: 40px 0 16px;
	&__inner {
		display: grid;
		grid-template-columns: 1fr 3fr 1fr;
    @include mobile {
      padding: 0 16px;
      grid-template-columns: 1fr;
      text-align: center;
      grid-row-gap: 24px;
    };
		.lead {
			.logo {
				height: 40px;
			}
			.tagline {
				@include text-variable-size($font-size-medium);
				color: $color-black;
				margin: 8px 0 0 56px;
        @include mobile {
          margin: auto;
        };
			}
		}
		.list {
			list-style: none;
			padding: 0 80px;
			margin: 0;
			text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr 0.5fr;
      grid-column-gap: 24px;
      @include mobile {
        grid-template-columns: 1fr;
        padding: 0;
        grid-row-gap: 24px;
      };
      .col {
        @include text-variable-size($font-size-medium);
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin: 8px auto;
        font-style: normal;
        color: $color-text-body;
        opacity: 0.75;
      }
			li {
				@include text-variable-size($font-size-base);
        text-align: left;
			}
			a {
				color: $color-gold;
        text-decoration: none;
			}
		}
		.mailinglist {
			@include text-variable-size($font-size-base);
			display: block;
			background-color: $color-gold;
			line-height: 48px;
			color: #fff;
			text-decoration: none;
			text-align: center;
		}
		.social {
			display: inline-block;
			img {
				height: 40px;
			}
		}
	}
  &__copyright {
    line-height: 44px;
    text-align: center;
    color: $color-text-default;
    opacity: 0.4;
    font-size: $font-size-small;
  }
}
