@import "@splidejs/splide/dist/css/splide.min.css";

.c-image-header {
	@extend %layout-column;
	position: relative;
	@include mobile {
		padding-top: 16px;
	};
	&__image {
		width: 100%;
		display: block;
		position: relative;
		z-index: 0;
	}
	&__overlay {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1;
		background-color: rgba(#000, 0.4);
		@include mobile {
			background-color: transparent;
			position: relative;
		};
		.content {
			position: absolute;
			bottom: 40px;
			left: 40px;
			color: #fff;
			@include mobile {
				position: relative;
				bottom: inherit;
				left: inherit;
			};
		}
		.title {
			@extend %Playfair-Display;
			@include text-variable-size($font-size-xlarge);
			font-style: italic;
			font-weight: bold;
			margin: 0 auto 4px;
			@include mobile {
				color: $color-text-default;
				margin-top: 16px;
			};
		}
		.description {
			@include text-variable-size($font-size-base);
			line-height: $line-height-default;
			color: #fff;
			margin: 0 auto 24px;
			@include mobile {
				color: $color-text-body;
			};
		}
		.button {
			line-height: 64px;
			max-width: 400px;
			display: block;
			color: $color-text-default;
			background-color: #fff;
			text-align: center;
			text-decoration: none;
			transition: background-color 0.25s;
			@include mobile {
				width: 100%;
				line-height: 44px;
				background-color: $color-black;
				color: #fff;
			};
			&:hover {
				background-color: $color-gold;
				color: #fff;
			}
		}
	}
}

.c-image-slider {
	@extend %layout-column;
	.splide__slide {
		padding: 0 8px;
	}
	img {
		width: 100%;
	}
}

.c-section-title {
	@include text-variable-size($font-size-large);
	color: $color-text-default;
	border-bottom: 1px solid $color-border;
	padding-bottom: 16px;
}

.l-custom-details {
	@extend %layout-column;
	margin: 80px auto;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-column-gap: 40px;
	@include mobile {
		grid-template-columns: 1fr;
		grid-row-gap: 24px;
	};
	&__body {
		@extend %text-body;
		@extend %linebreaks;
		max-width: $size-col-text;
		margin: auto;
		@include mobile {
			order: 2;
		};
	}
	&__image {
		display: block;
		width: 100%;
		@include mobile {
			order: 1;
		};
	}
}


.l-custom-process {
	@extend %layout-column;

	&__inner {
		list-style: none;
		padding: 0;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 40px;
		margin-top: 48px;
		margin-bottom: 48px;
		@include mobile {
			grid-template-columns: 1fr;
			grid-row-gap: 24px;
			margin: 24px auto;
		};
		.column {
			background-color: $color-background-light;
			padding: 24px;
			.title {
				@include text-variable-size($font-size-medium);
				color: $color-text-default;
				font-weight: bold;
				margin: auto;
				padding-bottom: 4px;
				border-bottom: 1px solid $color-border;
			}
			.description {
				@extend %text-body;
				margin: 8px auto 24px;
			}
			.sublist {
				@include text-variable-size($font-size-default);
				color: $color-text-body;
				padding-left: 16px;
				li {
					line-height: $line-height-wide;
				}
			}
		}
	}
}

.l-custom-team {
	@extend %layout-column;
}
