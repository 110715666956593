// @import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,400;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500;700&display=swap');



/* Font sizes */
$font-size-xxlarge: 3rem; // 48px
$font-size-xlarge: 2.25rem; // 36px
$font-size-large: 1.5rem; // 24px
$font-size-medium: 1.25rem; // 20px
$font-size-base: 1rem; // 16px
$font-size-default: 0.875rem; // 14px
$font-size-small: 0.75rem; // 12px
$font-size-smaller: 0.625rem; // 10px

/* Line Heights */
$line-height-wide: 2.0em;
$line-height-default: 1.6em;
$line-height-compact: 1.3em;

$size-col-width: 1200px;
$size-col-text: 700px;
$size-nav-height: 64px;
$size-mobile-nav-height: 48px;

$color-black: #000;
$color-white: #fff;
$color-dark: #282828;
$color-black: #000;
$color-gold: #c2a160;
$color-border: #eee;
$color-text-default: #333;
$color-text-body: #555;
$color-background-light: #f9f9f9;

/* Mixins */
@mixin mobile {
  @media only screen and (max-width: 640px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 641px) {
    @content;
  }
}

@mixin text-variable-size($size) {
  @if $size == $font-size-small {
    font-size: $font-size-smaller;
  }
  @if $size == $font-size-default {
    font-size: $font-size-default;
  }
  @if $size == $font-size-base {
    font-size: $font-size-base;
    @include tablet {
      font-size: $font-size-default;
    }
  }
  @if $size == $font-size-medium {
    font-size: $font-size-medium;
    @include tablet {
      font-size: $font-size-base;
    }
  }
  @if $size == $font-size-large {
    font-size: $font-size-large;
    @include tablet {
      font-size: $font-size-medium;
    }
  }
  @if $size == $font-size-xlarge {
    font-size: $font-size-xlarge;
    @include tablet {
      font-size: $font-size-large;
    }
  }
}

%linebreaks {
  @include tablet {
    br {
      display: none;
    }
  }
}

%Playfair-Display {
  font-family: "Playfair Display", serif;
}

%Space-Grotesk {
  font-family: 'Space Grotesk', sans-serif;
}

%text-body {
  @include text-variable-size($font-size-default);
  line-height: $line-height-default;
  color: $color-text-body;
}

%layout-column {
  max-width: $size-col-width;
  margin: auto;
  @include mobile {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
}
