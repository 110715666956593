.l-studio-header {
	@extend %Space-Grotesk;
	@extend %layout-column;
	margin-top: 64px;
	margin-bottom: 64px;
	&__title {
		@include text-variable-size($font-size-xlarge);
		@extend %linebreaks;
		font-weight: bold;
		color: $color-text-default;
	}
	&__desc {
		@extend %text-body;
		@extend %linebreaks;
		width: 50%;
		@include tablet {
			width: 100%;
		}
	}
}
.l-studio-grid {
	@extend %layout-column;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	list-style: none;
	padding: 0;
	@include mobile {
		grid-template-columns: 1fr;
		padding: 0 16px;
	};
	&__item {
		position: relative;
		.image {
			background-color: $color-background-light;
			width: 100%;
			height: auto;
			display: block;
		}
	}
}
