
@import "base/variables";
@import "components/cta";
@import "components/nav";
@import "components/footer";
@import "components/icon-list";
@import "pages/custom";
@import "pages/studio";
body {
  @extend %Space-Grotesk;
  margin: 0;
  &.light {
    background-color: #fff;
  }
}

.l-header {
  max-width: $size-col-width;
  margin: auto;
  background-position: center;
  background-size: cover;
  padding: 40px 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1.5fr;
  grid-column-gap: 40px;
  position: relative;
  @include mobile {
    grid-template-columns: 1fr;
    padding: 0 0 16px;
    grid-row-gap: 24px;
    background-color: rgba($color-dark, 0.05);
    &:before {
      display: none;
    }
  };
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 200px;
    top: 0;
    bottom: 0;
    background-color: rgba($color-black, 0.05);
    z-index: 0;
    @include mobile {
      display: none;
    };
  }
  &__tag-1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -24px;
    width: 16px;
    margin: auto;
    @include mobile {
      display: none;
    };
  }
  &__tag-2 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -24px;
    width: 16px;
    margin: auto;
    @include mobile {
      display: none;
    };
  }
  &__image {
    width: 100%;
    z-index: 2;
    position: relative;
    @include mobile {
      order: 1;
    };
  }
  &__content {
    position: relative;
    z-index: 2;
    padding-left: 64px;
    @include mobile {
      order: 2;
      padding: 0 16px;
    };
    .lead {
      margin: auto;
      text-transform: uppercase;
      font-style: italic;
      letter-spacing: 0.1em;
    }
    .title {
      @extend %Playfair-Display;
      @include text-variable-size($font-size-xlarge);
      color: $color-black;
      font-weight: 900;
      margin: 0 auto 12px;
      padding: 0;
      @include mobile {
        margin-bottom: 4px;
      };
    }
    .details {
      font-size: $font-size-default;
      line-height: $line-height-default;
      color: $color-text-body;
      margin: auto;
      @include mobile {
        font-size: $font-size-default;
      };
    }
    a.button {
      @include text-variable-size($font-size-base);
      display: block;
      background-color: $color-black;
      line-height: 56px;
      margin-top: 24px;
      text-align: center;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      transition: background-color 0.25s;
      &:hover {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }
}

.l-concept {
  width: 100%;
  background-color: $color-black;
  padding: 80px 0;
  margin: 80px auto;
  @include mobile {
    padding: 16px 0;
    margin-top: 0;
  };
  &__inner {
    max-width: $size-col-width;
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1.5fr;
    grid-column-gap: 40px;
    margin: auto;
    .image {
      width: 100%;
    }
    @include mobile {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
      padding: 0 16px;
    };
  }
  &__content {
    .lead {
      @extend %Playfair-Display;
      @extend %linebreaks;
      @include text-variable-size($font-size-xlarge);
      line-height: $line-height-default;
      font-weight: bold;
      margin: 0 0 4px;
      color: $color-gold;
    }
    .details {
      @include text-variable-size($font-size-default);
      margin: auto;
      color: #fff;
      line-height: $line-height-default;
    }
    .button {
      line-height: 56px;
      height: 56px;
      display: inline-block;
      padding: 0 32px;
      margin: 24px 0;
      background-color: $color-gold;
      text-decoration: none;
      color: $color-black;
      font-size: $font-size-base;
      font-weight: bold;
      text-align: center;
      transition: background-color 0.25s;
      @include mobile {
        display: block;
        padding: 0;
        margin-bottom: 0;
      };
      &:hover {
        background-color: #fff;
      }
    }
  }

}

.l-image {
  margin: 48px auto 0;
  position: relative;
  img {
    width: 100%;
    display: block;
  }
}


.l-product {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  max-width: $size-col-width;
  margin: 80px auto;
  grid-column-gap: 40px;
  align-items: center;
  @include mobile {
    margin: 0 auto;
    grid-template-columns: 1fr;
  }
  &__image {
    max-width: $size-col-width;
    margin: auto;
    img {
      width: 100%;
      display: block;
    }
  }
  &__content {
    color: #333;
    @include mobile {padding: 24px 16px;

    };
    .title {
      font-size: $font-size-xlarge;
      font-weight: bold;
      line-height: $line-height-compact;
      margin: 0 auto 12px;
      @include mobile {
        font-size: $font-size-large;
      };
    }
    .desc {
      font-size: $font-size-base;
      line-height: $line-height-default;
      @include mobile {
        font-size: $font-size-default;
      };
    }
    .details {
      border-top: 1px solid #ddd;
      padding-top: 16px;
      font-size: $font-size-default;
      line-height: $line-height-default;
      color: rgba(#333, 0.7);
    }
    .email {
      font-size: $font-size-base;
      font-weight: bold;
      color: $color-gold;
      text-align: center;
      line-height: $line-height-default;
      margin: 32px 0 0;
      @include mobile {
        br {
          display: none;
        }
      };
    }
    .date {
      text-align: center;
      font-size: $font-size-small;
    }
    .button {
      margin-top: 12px;
      background-color: $color-gold;
      display: block;
      line-height: 56px;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      color: #fff;
      transition: background-color 0.25s;
      &:hover {
        background-color: $color-black;
      }
    }
  }
}

.l-story {
  max-width: $size-col-width;
  margin: auto;
  position: relative;
  &__image {
    width: 100%;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    padding: 80px 0;
    @include mobile {
      padding: 24px 16px;
      grid-template-columns: 1fr;
    };
    .subtitle {
      @extend %Playfair-Display;
      @include text-variable-size($font-size-medium);
      margin: auto;
      color: $color-gold;
    }
    .title {
      @extend %Playfair-Display;
      @include text-variable-size($font-size-xlarge);
      color: $color-gold;
      font-weight: bold;
      line-height: $line-height-default;
      margin: auto;
    }
    .details {
      @include text-variable-size($font-size-default);
      line-height: $line-height-default;
      color: $color-text-body;
    }
  }
}
