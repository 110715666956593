.c-icon-list {
	max-width: $size-col-width;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	list-style: none;
	padding: 0;
	margin: 40px auto;
	@include mobile {
		grid-column-gap: 40px;
		padding: 0 16px;
	};
	&__item {
		.icon {
			display: block;
			margin: auto;
			width: 64px;
			@include mobile {
				width: 100%;
			};
		}
		.title {
			@extend %Playfair-Display;
			@include text-variable-size($font-size-base);
			text-align: center;
			color: $color-gold;
			margin: auto;
		}
	}
}
